// App.js

import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import HomePage from './components/HomePage';
import css from './App.css';

import Footer from './components/Footer';
import CatalogPage from './components/CatalogPage';
import {BrowserRouter as Router, Route, Link, Routes} from "react-router-dom"
import ProductPage from './components/ProductPage';


function App() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('https://resanta-uzbekistan.com/wp-json/wc/v3/products/categories', {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + btoa('ck_a9ce86c49402ce867b37ea156612afe08dd76cee:cs_3c8a11614cc23b0b4f7eb656677825dadae986f6'),
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      setCategories(data);
    })
    .catch(error => {
      console.error('Error fetching categories:', error);
    });
  }, []);

  return (
    
    <div className="App">
      <Header/>
      <Router>
          <Routes>
              <Route path="/" element={<HomePage categories={categories}/>}/>
              <Route path="/catalog" element={<CatalogPage/>}/>
              <Route path="/catalog/product" element={<ProductPage/>}/>
          </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
