import BtnTo from "../BtnTo"

const CaregoriesCard = ({title, descr, link}) =>{
	return(
		<div className="categoriesCard">
			<h2>
				{title}
			</h2>
			<p>
				{descr}
			</p>
			<BtnTo text="Go to the category" href={link}/>
		</div>
	)
}

export default CaregoriesCard;