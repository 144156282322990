
const ProductCard = () => {
	return(
		<div className="productCard">
			<div className="productCard__leftContent">
				<span className="NewsSticker">
					New
				</span>
				<div className="productCard__image">
					<img src="https://resanta-uzbekistan.com/wp-content/uploads/2023/10/banner1-1.png" alt="" />
				</div>
			</div>
			<div className="productCard__rightContent">
				<div className="productCard__info">
					<span className="productCard__catName">catName</span>
					<span className="productCard__prodName">Name</span>
				</div>
				<div className="productCard__characteristics">
					<span>Характеристики</span>
					<table>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
							<tr>
								<td>Antistick</td>
								<td>да</td>
							</tr>
					</table>
					<table>
							<tr>
								<td>смотреть все</td>
							</tr>
					</table>
				</div>
			</div>
		</div>
	)
}

export default ProductCard;