

const CatalogNav = () =>{
	return(
		<div className="navCategories__card">
			<ul>
				<li>
					Autotransformers
				</li>
				<li>
					Autotransformers
				</li>
				<li>
					Autotransformers
				</li>
				<li>
					Autotransformers
				</li>
			</ul>
		</div>
	)
}

export default CatalogNav;