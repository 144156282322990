import BigCube from "../BigCube";
import CatalogNav from "./CatalogNav";
import ProductCard from "./ProductCard";


const CatalogPage = () =>{

	return(
		<>
		<div className="CatalogPage">
			<div className="container">
				<div className="navCategories">
					<CatalogNav/>
				</div>
				<div className="producCards">
					<ProductCard/>
					<ProductCard/>
					<ProductCard/>
					<ProductCard/>
					<ProductCard/>
				</div>
			</div>
			
		</div>
		</>
	)
}

export default CatalogPage;