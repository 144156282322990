import firstImg from '../../assets/images/aboutUs/img1.png';
import secondImg from '../../assets/images/aboutUs/img2.png';

const AboutUs = () => {
	return(
		<section className='aboutUs'>
			<div className="container flex row">
				<div className='aboutUs__leftContent flex column'>
					<div className='flex row'>
						<div className="aboutUs__card">
							<h2 className="titleSection">
								About us
							</h2>
						</div>
						<div className="aboutUs__card">
							<img src={firstImg} alt="" />
						</div>
					</div>
					<div className='aboutUs__bigCard'>
						<div className="aboutUs__card">
							<img src={secondImg} alt="" />
						</div>
					</div>
				</div>
				<div className='aboutUs__rightContent flex column'>
					<p className="aboutUs__textBold">
						Resanta is a brand that has earned the trust of consumers over several decades of existence and has firmly established itself in the Russian market.
					</p>
					<p className="aboutUs__text">
						The range, which includes only welding equipment at the start of production, has expanded significantly today, and now the RESANTA trademark offers customers more than 500 items of goods, including:
					</p>
					<ul>
						<li>Voltage stabilizers are models with wall mounting and floor mounting, varying accuracy, input voltage ranges and design. The products are designed to protect electrical equipment from voltage fluctuations in the network.</li>
						<li>Thermal equipment — from household appliances (fan heaters, oil radiators, convectors) to devices that are designed to heat industrial and warehouse premises (infrared heaters, heat curtains, electric heat guns and guns powered by gas and diesel fuel).</li>
						<li>High—precision measuring equipment - laser levels and rangefinders, which will become faithful assistants to specialists and will always be useful in everyday life.</li>
						<li>Welding equipment — welding machines for various types of welding, as well as accessories and protective equipment.</li>
						<li>RESANT's garden equipment includes a wide range and model range of car washes, trimers and lawn mowers, saws, cultivators and agricultural machines, as well as snow removal equipment.</li>
						<li>Pumping equipment has taken a strong position in the Russian market.</li>
						<li>A wide range of power tools for all types of work.</li>
					</ul>
				</div>
			</div>
		</section>
	)
}

export default AboutUs;