import BtnTo from "../BtnTo";

const Contacts = () => {

	return(
		<section className="contacts">
			<div className="container flex row">
				<div className="contacts__redCard flex"><h2 className="titleSection">Contacts</h2></div>
				<div className="contacts__grayCard flex column">
					<div className="contacts__grayTop">
						<h3>Support</h3>
						<div>
							<div>
								<svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.720559 0C0.303393 0 0 0.269663 0 0.640449V26.3596C0 26.7303 0.303393 27 0.720559 27H18.2794C18.6966 27 19 26.7303 19 26.3596V0.640449C19 0.269663 18.6966 0 18.2794 0L0.720559 0ZM3.79241 3.37079H15.1697V20.2247H3.79241V3.37079ZM9.48104 21.9101C10.5429 21.9101 11.3772 22.6517 11.3772 23.5955C11.3772 24.5393 10.5429 25.2809 9.48104 25.2809C8.41916 25.2809 7.58483 24.5393 7.58483 23.5955C7.58483 22.6517 8.41916 21.9101 9.48104 21.9101Z" fill="#DD4023"/>
								</svg>
								<span>8-999-999-99-99</span>
							</div>
							<div>
								<svg width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9.5 0L0 6.5V26H19V6.5L9.5 0ZM9.5 3.6725L16.625 8.5475V14.6575L9.5 19.5325L2.375 14.6575V8.5475L9.5 3.6725ZM4.75 9.7825V13.0325L9.5 16.2825L14.25 13.0325V9.7825H4.75Z" fill="#DD4023"/>
								</svg>
								<span>info@resanta.com</span>
							</div>
						</div>
					</div>
					<div>
						<BtnTo text='Contact us' href=''/>
					</div>
				</div>
				<div>
					<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d17951.12849250091!2d37.6993667!3d55.77776384999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2snl!4v1707486787950!5m2!1sru!2snl" width="486" height="472" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>
		</section>
	)
}

export default Contacts;