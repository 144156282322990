import bannerlogo from '../../assets/images/bannerlogo.png';
import BtnTo from '../BtnTo';
import bannerimg from '../../assets/images/bannerimg.png'
import BigCube from '../BigCube';
const MainBanner = () =>{

	return(
		<section className='mainBanner flex column align-center justify-center'>
			<div className="container flex">
				<div className="banner__leftcontent">
					<div className="banner__text flex column">
						<span>
						The official online store of the manufacturer
						</span>
						<img src={bannerlogo} alt="" />
						<BtnTo text="Products" href='/products'/>
					</div>	
				</div>
				<div className="banner__lrightcontent flex justify-center align-center">
					<img src={bannerimg} alt="Product" />
				</div>
			</div>
			<BigCube/>
		</section>
	)
}

export default MainBanner;