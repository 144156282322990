

const BtnTo = ({text, href}) =>{
	return(
	<a className="btnTo flex row align-center" href={href}>
		<span>{text}</span>
		<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
		<ellipse cx="28" cy="28.25" rx="28" ry="27.5" fill="#E55022"/>
		<path d="M45.0607 29.3107C45.6464 28.7249 45.6464 27.7751 45.0607 27.1893L35.5147 17.6434C34.9289 17.0576 33.9792 17.0576 33.3934 17.6434C32.8076 18.2292 32.8076 19.1789 33.3934 19.7647L41.8787 28.25L33.3934 36.7353C32.8076 37.3211 32.8076 38.2708 33.3934 38.8566C33.9792 39.4424 34.9289 39.4424 35.5147 38.8566L45.0607 29.3107ZM12 29.75H44V26.75H12V29.75Z" fill="white"/>
		</svg>
	</a>
	)
}


export default BtnTo;