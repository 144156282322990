import { React, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import CaregoriesCard from './CaregoriesCard';

const Categories = ({categories}) => {
    const swiperRef = useRef(null);
    console.log(categories);
    const handleNextSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handlePrevSlide = () => {
	if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
	    swiperRef.current.swiper.slidePrev();
	}
  };


   const CarArray = categories.map(item=>(
    <SwiperSlide><CaregoriesCard title={item.name} descr="Power tools are indispensable helpers in any household or construction site. They make it much easier and faster to perform various works" link=""/></SwiperSlide>
    ));

    return (
        <section className="categories">
            <div className="container flex">
                <div className="categories__leftContent">
                    <div className="categories__card flex">
                        <h2 className="titleSection">
                            Categories
                        </h2>
                    </div>
                    <div className='categories__buttons'>
                <button onClick={handlePrevSlide}><svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 6.5L11.25 0.00480938V12.9952L0 6.5Z" fill="#CE2022" />
                    <path d="M0 6.5L11.25 0.00480938V12.9952L0 6.5Z" fill="#CE2022" />
                    </svg></button>
                                        <button onClick={handleNextSlide}><svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6.5L0.75 12.9952V0.00480938L12 6.5Z" fill="#CE2022" />
                    <path d="M12 6.5L0.75 12.9952V0.00480938L12 6.5Z" fill="#CE2022" />
                    </svg></button>
                            </div>
                </div>


                <div className="categories__rightContent">
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={33}
                        slidesPerView={'auto'}
                        freeMode={true}
                        loop={true}
                    >
                        {CarArray}
                        
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Categories;
