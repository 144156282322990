
import BtnTo from "../BtnTo";

const ProductPage = () =>{

	return(
		<div className="productPage">
			<div className="container">
			<div className="btnTo">
				<BtnTo text="Back"/>
			</div>
			<div className="ProductPage__contentCont">
				<div className="productPage__thumbnailCont">
					<img src="https://resanta-uzbekistan.com/wp-content/uploads/2024/01/00fba935a220b22ed32fba935a-100x100.png" alt="" />
				</div>
				<div className="productPage__imageGallery">
					<img src="https://resanta-uzbekistan.com/wp-content/uploads/2024/01/00fba935a220b22ed32fba935a-100x100.png" alt="" />
					<img src="https://resanta-uzbekistan.com/wp-content/uploads/2024/01/00fba935a220b22ed32fba935a-100x100.png" alt="" />
					<img src="https://resanta-uzbekistan.com/wp-content/uploads/2024/01/00fba935a220b22ed32fba935a-100x100.png" alt="" />
					<img src="https://resanta-uzbekistan.com/wp-content/uploads/2024/01/00fba935a220b22ed32fba935a-100x100.png" alt="" />
					<img src="https://resanta-uzbekistan.com/wp-content/uploads/2024/01/00fba935a220b22ed32fba935a-100x100.png" alt="" />
				</div>

				<div className="productPage__infoCont">
				<span className="NewsSticker">
					New
				</span>
					<span className="productPage__catName">catName</span>
					<span className="productPage__prodName">Name</span>
					<span className="productPage__descrTitle">Description</span>
					<div className="productPage__description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div>
				</div>
			</div>
			<div className="productPage__characteristicsCont">
				<div className="productPage__charItem">Type1:Type</div>
				<div className="productPage__charItem">Type1:Type</div>
				<div className="productPage__charItem">Type1:Type</div>
				<div className="productPage__charItem">Type1:Type</div>
			</div>
			</div>
		</div>

	)

}

export default ProductPage;