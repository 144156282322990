import logo from '../assets/images/logo.png';



const Footer = () =>{
	return(
		<footer>
			<div className="container flex">
				<div className="footer__logoContainer">
					<img src={logo} alt="Resanta"/>
				</div>

				<div className='footer__navContainer flex'>
					<nav className='flex align-center'>
						<a href="">About us</a>
						<a href="">Catalog</a>
						<a href="">Contact</a>
					</nav>
					<div className="footer__search">
						<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M20.299 18.5305C21.8393 16.605 22.7603 14.1626 22.7603 11.5051C22.7603 5.28909 17.7211 0.25 11.5051 0.25C5.28909 0.25 0.25 5.28909 0.25 11.5051C0.25 17.7211 5.28909 22.7603 11.5051 22.7603C14.1626 22.7603 16.605 21.8393 18.5305 20.299L25.6151 27.3838C26.1035 27.8721 26.8954 27.8721 27.3838 27.3838C27.8721 26.8954 27.8721 26.1035 27.3838 25.6151L20.299 18.5305ZM11.5051 20.2716C6.6635 20.2716 2.73859 16.3468 2.73859 11.5051C2.73859 6.6635 6.6635 2.73859 11.5051 2.73859C16.3468 2.73859 20.2716 6.6635 20.2716 11.5051C20.2716 16.3468 16.3468 20.2716 11.5051 20.2716Z" fill="white"/>
						</svg>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;