import MainBanner from './MainBanner';
import AboutUs from './AboutUs';
import Categories from './Categories';
import Contacts from './Contacts';

const HomePage = ({categories}) => {
	return(
		<>
			<MainBanner/>
			<AboutUs/>
			<Categories categories={categories} />
			<Contacts/>
		</>
	)
}

export default HomePage;